import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import LangService from "./core/LangService";
import LocalStorageService from "./core/LocalStorageService";
import LocaleService from "./core/LocaleService";
import "./index.module.scss";
import AppRoutes from "./layout/AppRoutes";
import AuthGuarder from "./layout/AuthGuarder";
import reportWebVitals from "./reportWebVitals";
import { initDataTracking } from "./utils/sensorsDataTracking";

// init sa tracking
(() => {
  initDataTracking();
})();

// init i18n object
(() => {
  const lang =
    LocalStorageService.getStorage(LocalStorageService.LOCAL_LANG) ||
    LocaleService.getDefaultLocale()?.lang;
  LangService.getInstance().setLang(lang);
})();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <RecoilRoot>
    <AuthGuarder>
      <AppRoutes />
    </AuthGuarder>
  </RecoilRoot>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
