export const sensors = require('sa-sdk-javascript');

export function initDataTracking() {
    const { REACT_APP_WECHAT_SENSORS_URI } = process.env;

    sensors.init({
        server_url: REACT_APP_WECHAT_SENSORS_URI,
        is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
        use_client_time: true,
        send_type: 'beacon',
        heatmap: {
            //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
            clickmap: 'not_collect',
            //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
            scroll_notice_map: 'not_collect',
        },
        show_log: false,
    });
    sensors.registerPage({
        platform_type: 'H5',
        project: 'RMS Wise Installation',
        pageArea: 'web',
        pageCountry: 'China',
        pageLanguage: 'zh-cn',
        pageIdentifier: 'RMS Wise Installation',
    });
    sensors.quick('autoTrack'); //用于采集 $pageview 事件。
    console.log('Sensor initialized');
}

/**
 * tracking events
 * @param {*} eventName
 * @param {*} data
 */
export function sendEvent(eventName: any, eventAction: any, eventValue: any, eventDetail: any, division: any, userId: any) {
    const newData = {
        pagePool: division,
        eventName,
        eventAction,
        eventValue,
        eventDetail,
        userID: userId,
    };
    sensors.track(eventName, newData);
}
